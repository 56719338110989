import { GrDocumentUser } from "react-icons/gr";

import { RiFileUserLine } from "react-icons/ri";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const Requests = ({ open = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "request-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Request's"}
        open={open}
        active={location.pathname.includes("dashboard/requests")}
        click={() => navigate("requests")}
      >
        <RiFileUserLine />
      </BtnTemplate>
      <Tooltip
        id={id}
        txt1="Make requests to the store"
        txt2="View past requests"
      >
        <RiFileUserLine />
      </Tooltip>
    </div>
  );
};

export default Requests;
