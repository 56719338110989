import { FiBell } from "react-icons/fi";

import useApp from "../../Hooks/useApp";

import NetworkBadge from "../Badges/NetworkBadge";

const BellTopBar = ({ label }) => {
  const { isOnline } = useApp();

  return (
    <div className="flex justify-between items-center px-7 py-4 shadow-md">
      <p className=" font-DM-Bold text-primary text-2xl ">{label}</p>
      {!isOnline && (
        <div className=" text-8xl text-cadet_grey cursor-pointer active:text-primary ">
          <NetworkBadge />
        </div>
      )}
    </div>
  );
};

export default BellTopBar;
