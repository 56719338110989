import PouchDB from "pouchdb";
import pouchDbFind from "pouchdb-find";

import usePouch from "./usePouch";

import useApp from "./useApp";

PouchDB.plugin(pouchDbFind);

const useIntialize = () => {
  const { setIsLoading } = useApp();

  const { fetchById } = usePouch();

  const intialize = async () => {
    try {
      setIsLoading(true);
      let doc = await fetchById({
        db: "medico",
        id: "medico",
      });

      let { user, exp, token } = doc;
      if (exp == null || user == null || token == null) {
        return "error";
      }

      if (exp < Date.now()) {
        return "error";
      }
      return doc;
    } catch (e) {
      let db = new PouchDB("medico");
      db.put({
        _id: "medico",
        user: null,
        token: null,
        exp: null,
      });
      return "error";
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  async function log_out() {
    try {
      return await update({ user: null, token: null, exp: null });
    } catch (e) {
      return "error";
    }
  }

  async function update(doc) {
    try {
      setIsLoading(true);
      let db = new PouchDB("medico");
      let oldDoc = await db.get("medico");

      return await db.put({
        _id: "medico",
        _rev: oldDoc._rev,
        ...doc,
      });
    } catch (e) {
      return "error";
    } finally {
      setIsLoading(false);
    }
  }

  return { update, intialize, log_out };
};

export default useIntialize;
