import React, { useRef, useContext, useState } from "react";

import FilterModal from "../../../../../../../Components/Modals/FilterModal";

import StateInput from "../../../../../../../Components/StateInput/SelectInput";

import DatePicker from "../../../../../../../Components/Inputs/DatePicker";

import { convDate2, toTimeStamp } from "./../../../../../../../Util/Time";

import AllContext from "../../AllContext";

const TblFilter = ({ showFilter, setShowFilter }) => {
  const { handleFilterQuery } = useContext(AllContext);
  const [gender, setGender] = useState("");

  const input = useRef({ form: { minDate: "", maxDate: "" }, err: {} });

  async function filterFunc() {
    let filters = {};
    let to_filter = false;

    if (gender !== "") {
      filters.filter_gender = gender;
      to_filter = true;
    }

    if (input.current.form.minDate !== "") {
      filters.filter_start_dob = toTimeStamp(input.current.form.minDate);
      to_filter = true;
    }

    if (input.current.form.maxDate !== "") {
      filters.filter_end_date = toTimeStamp(input.current.form.minDate);
      to_filter = true;
    }

    if (to_filter) {
      handleFilterQuery(filters);
      setShowFilter(false);
      return;
    }
  }

  return (
    <FilterModal
      showFilter={showFilter}
      setShowFilter={setShowFilter}
      apply={filterFunc}
    >
      <div>
        <h2 className=" font-DM-Bold text-xl my-3 text-cadet_grey">GENDER</h2>
        <StateInput
          input={gender}
          setInput={setGender}
          config={{
            required: false,
            firstDisabled: false,
            items: [
              { label: "None", value: "" },
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
              { label: "Other", value: "Other" },
            ],
            label: "Select Gender",
          }}
        />
      </div>

      <div className=" mt-4">
        <h2 className=" font-DM-Bold text-xl text-cadet_grey ">DOB</h2>
        <DatePicker
          initial={""}
          input={input}
          inputKey="minDate"
          mt={0}
          mb={0}
          config={{
            required: false,
            disabled: false,
            max: convDate2(Date.now()),
            label: "Start DOB",
          }}
        />
        <DatePicker
          initial={""}
          input={input}
          inputKey="maxDate"
          mt={-2.5}
          mb={0}
          config={{
            required: false,
            disabled: false,
            max: convDate2(Date.now()),
            label: "End DOB",
          }}
        />
      </div>
    </FilterModal>
  );
};

export default TblFilter;
