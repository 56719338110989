import PouchDB from "pouchdb";
import pouchDbFind from "pouchdb-find";

import { dbs } from "./../conf";

import useTables from "./../Pages/Dashboard/Hooks/useTables";

PouchDB.plugin(pouchDbFind);

const usePouch = () => {
  const tbs = useTables();

  async function fetchById({ db, id }) {
    const DB = new PouchDB(db);
    let doc = await DB.get(id);
    return doc;
  }

  async function findAll({
    db,
    index,
    selector = {},
    sort = [{ _id: "asc" }],
  }) {
    const DB = new PouchDB(db);
    await DB.createIndex({
      index: { fields: index },
    });

    let data = await DB.find({
      selector: selector,
      sort: sort,
    });
    return data.docs;
  }

  async function findAll2({
    db,
    index,
    selector = {},
    sort = [{ _id: "asc" }],
  }) {
    try {
      const DB = new PouchDB(db);
      await DB.createIndex({
        index: { fields: index },
      });

      let data = await DB.find({
        selector: selector,
        sort: sort,
      });
      return data.docs;
    } catch (e) {
      // console.log(e);
      return "error";
    }
  }

  async function limitFind({ db, index, selector = {}, sort, limit = 5 }) {
    const DB = new PouchDB(db);
    await DB.createIndex({
      index: { fields: index },
    });

    let data = await DB.find({
      selector: selector,
      sort: sort,
      limit: limit,
    });
    return data.docs;
  }

  async function deleteIndexes({ db }) {
    const DB = new PouchDB(db);
    let indexesResult = await DB.getIndexes();
    let indexes = indexesResult.indexes;

    for (let i = 0; i < indexes.length; i++) {
      try {
        let ind = indexes[i];

        await DB.deleteIndex(ind);
      } catch (e) {}
    }
  }

  async function deleteDB({ db }) {
    const DB = new PouchDB(db);
    await DB.destroy();
  }

  async function clearData() {
    for (let db of dbs) {
      try {
        await deleteIndexes({ db });
        await deleteDB({ db });
      } catch (e) {
        //console.log(e);
      }
    }
  }

  async function clearData2() {
    for (let table of tbs) {
      try {
        await deleteIndexes({ db: table.name });
        await deleteDB({ db: table.name });
      } catch (e) {
        //console.log(e);
      }
    }
  }

  async function clearData3(name) {
    await deleteIndexes({ db: name });
    await deleteDB({ db: name });
  }

  return {
    fetchById,
    findAll,
    findAll2,
    limitFind,
    deleteIndexes,
    deleteDB,
    clearData,
    clearData2,
    clearData3,
  };
};

export default usePouch;
