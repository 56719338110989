import React, { useState, useEffect } from "react";
import { BiPlus } from "react-icons/bi";

import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";

import AddKin from "./AddKin";

import Details from "./Details";

const KinDetails = ({
  kins,
  setKins,
  activeKin,
  setActiveKin,
  relations,
  init,
}) => {
  const [showAdd, setshowAdd] = useState(false);

  return (
    <div className=" ml-5 mt-8 relative  mr-5 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
      <div className="mb-5 mt-5 p-4">
        <div className="flex justify-between mb-5">
          <h5 className="font-bold inline-flex items-center">
            NEXT OF KIN DETAILS
          </h5>
          <div className="pl-5">
            <BtnPlain
              bg="primary"
              textColor="white"
              click={() => setshowAdd(true)}
            >
              <div className="flex flex-nowrap w-full text-[16px] items-center px-2 py-1 space-x-2">
                <BiPlus />
                <span>Add New</span>
              </div>
            </BtnPlain>
          </div>
        </div>

        <hr className="w-full" />
        <Details
          kins={kins}
          setKins={setKins}
          activeKin={activeKin}
          setActiveKin={setActiveKin}
          relations={relations}
          init={init}
        />
        {showAdd && (
          <AddKin
            setShowModal={setshowAdd}
            showModal={showAdd}
            setKins={setKins}
            activeKin={activeKin}
            setActiveKin={setActiveKin}
            relations={relations}
            init={init}
          />
        )}
      </div>
    </div>
  );
};

export default KinDetails;
