import id from "date-fns/esm/locale/id/index.js";
import { useNavigate } from "react-router-dom";

import { ActionBtn } from "../../../../../../../Components/FinalTable";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from 'react-to-print';
import Invoice from "../Invoice";
import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";
import { FaPrint } from "react-icons/fa";
import useAxios from "../../../../../../../Hooks/useAxios";


const TableAction = ({ doc }) => {
  const [billingDetails, setBillingDetails] = useState({})
  const navigate = useNavigate();
  const InvoiceRef = useRef()
  const request = useAxios()
  const [isReady, setIsReady] = useState("none");
  function handleNext() {
    navigate(`/dashboard/next-step/${doc.id}`);
    return;
    if (doc.checked_out === true) {
      return;
    }
  }
  async function getDetails(){
    try {
      let res = await request({
        method: "GET",
        url: `hospital/visit/bill?visit_id=${doc.id}`,
      });
      setBillingDetails(res)
     
    } catch (error) {
      console.log(error)
    }
    
  }

  useEffect(()=> {
    getDetails()
  },[])

  const handlePrint = useReactToPrint({
    content: () => InvoiceRef.current,
  });

  return (
    <div className="flex-1">
      <div className="flex">
          <div style={{ display: isReady }} >
             <Invoice ref={InvoiceRef} props={billingDetails} />
          </div>
      </div>
      {/* <TableHorizontalActionBtn /> */}
      <ActionBtn showOptions={true} next={handleNext}>
         <div className=" gap-y-2 flex flex-col text-sm">
                    
                        <BtnPlain
                        activeBgColor="white"
                        bg="white"
                        click={handlePrint}
                    >
                        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                            <p className="  text-primary">
                                <FaPrint />
                            </p>
                            <p className=" ">Print Invoice</p>
                        </div>
                    </BtnPlain>
                    
                </div>
      </ActionBtn>
    </div>
  );
};

export default TableAction;
