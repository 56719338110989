import React, { useState, useEffect, useRef } from "react";
import PouchDB from "pouchdb";
import pouchDbFind from "pouchdb-find";

import { toast } from "react-toastify";

import usePouch from "../../../../../Hooks/usePouch";

PouchDB.plugin(pouchDbFind);

const usePouchPagination = (obj) => {
  const {
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    user_id,
  } = obj;

  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [total, setTotal] = useState(0);
  const [reset, setReset] = useState(0);

  const [c, setC] = useState(0);

  const timeOut = useRef(null);

  const db = new PouchDB(db_name);

  const index = useRef(initialIndex);

  const secondarySelector = useRef({});

  const sort = useRef(initialSort);
  const selector = useRef(initialSelector);
  const pageKeys = useRef({});

  const { findAll } = usePouch();

  useEffect(() => {
    async function fetchDocs() {
      await handleInitialFetch(initialSelector);
    }

    fetchDocs();
  }, [reset]);

  useEffect(() => {
    setReset(reset + 1);
  }, [limit]);

  useEffect(() => {}, []);

  async function getDocs() {
    try {
      setTableIsLoading(true);
      let res = await db.createIndex({
        index: { fields: index.current },
      });

      let spt = await findAll({
        db: "space_team",
        index: ["id", "user_id"],
        selector: { id: { $gte: null }, user_id: { $eq: parseInt(user_id) } },
        sort: [{ id: "asc" }],
      });

      let orFil = spt.map((space_team) => ({
        space_id: { $eq: space_team.space_id },
        user_id: { $eq: null },
      }));

      let data = await db.find({
        selector: {
          ...selector.current,
          ...{ $or: orFil },
        },
        sort: sort.current,
        limit: parseInt(limit),
      });

      return data.docs;
    } catch (e) {
      return [];
    } finally {
      setTimeout(() => {
        setTableIsLoading(false);
      }, 5000);
    }
  }

  async function handleInitialFetch() {
    selector.current = initialSelector;
    index.current = initialIndex;
    sort.current = initialSort;
    secondarySelector.current = {};
    let docs = await getDocs();

    setTotal("...");
    setData(() => docs);
    setThePageKeys(docs, 1);
    setPage(() => 1);
    if (docs.length < limit) {
      setHasNextPage(() => false);
    } else {
      setHasNextPage(() => true);
    }
  }

  function setThePageKeys(docs, p) {
    let newPageKeys = { ...pageKeys.current };
    newPageKeys[`page${p}`] = { st: docs[0], en: docs[docs.length - 1] };
    pageKeys.current = newPageKeys;
  }

  async function handleNext() {
    let s = sort.current[0][index.current[0]];

    if (hasNextPage === false) return;

    let select = {};

    if (s === "asc") {
      select[index.current[0]] = {
        $gte: pageKeys.current[`page${page}`].en[index.current[0]],
      };
    } else {
      select[index.current[0]] = {
        $lte: pageKeys.current[`page${page}`].en[index.current[0]],
      };
    }

    selector.current = select;

    let docs = await getDocs();

    if (docs.length < limit - 1) {
      setHasNextPage(() => false);
    } else {
      setHasNextPage(() => true);
    }
    setHasPrevPage(() => true);
    setPage((c) => c + 1);
    setThePageKeys(docs, page + 1);
    setData(() => docs);
  }

  async function handlePrev() {
    if (page === 1) return;

    let select = {};

    let s = sort.current[0][index.current[0]];

    if (s === "asc") {
      select[index.current[0]] = {
        $gte: pageKeys.current[`page${page - 1}`].st[index.current[0]],
      };
    } else {
      select[index.current[0]] = {
        $lte: pageKeys.current[`page${page - 1}`].st[index.current[0]],
      };
    }

    if (page - 1 === 1) {
      setHasPrevPage(() => false);
    }
    selector.current = select;

    let docs = await getDocs();
    setPage((c) => c - 1);
    setThePageKeys(docs, page - 1);
    setHasNextPage(() => true);
    setData(() => docs);
  }

  async function resetQuery() {
    setReset((c) => c + 1);
  }

  async function handleHeaderQuery(indexInit, sortInit, selectorInit) {
    index.current = indexInit;
    sort.current = sortInit;
    selector.current = selectorInit;
    secondarySelector.current = {};

    const docs = await getDocs(selector);
    setPage(1);
    setThePageKeys(docs, 1);
    setData(docs);
    setHasPrevPage(false);
    if (docs.length < limit) {
      setHasNextPage(false);
    } else {
      setHasNextPage(true);
    }
  }

  async function handleFilterQuery(
    indexInit,
    sortInit,
    selectorInit,
    secondarySelectorInit
  ) {
    index.current = indexInit;
    sort.current = sortInit;
    selector.current = selectorInit;
    secondarySelector.current = secondarySelectorInit;
    const docs = await getDocs(selector);
    setPage(1);
    setThePageKeys(docs, 1);
    setData(docs);
    setHasPrevPage(false);
    if (docs.length < limit) {
      setHasNextPage(false);
    } else {
      setHasNextPage(true);
    }
  }

  return {
    total,
    setTotal,
    page,
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    resetQuery,
    handleHeaderQuery,
    handleFilterQuery,
  };
};

export default usePouchPagination;
