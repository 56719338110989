import React, { createContext } from "react";
import HeaderBtn from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import {
  TableHeaderRow,
  HorizontalActionBtn,
} from "./../../../../../../../Components/FinalTable";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={90 / 6} label="NAME" />
        <HeaderBtn w={90 / 6} label="CATEGORY" />
        <HeaderBtn w={90 / 6} label="MEASURE" />
        <HeaderBtn w={90 / 6} label="STORE QUANTITY" />
        <HeaderBtn w={90 / 6} label="QTY" />
        <div style={{ width: "10%" }}></div>
      </TableHeaderRow>
    </div>
  );
};

export default THead;
