import BellTopBar from "../../../../../Components/Bars/BellTopBar";
import Drugs from "./Components/Drugstbl";

import PersonalInfo from "./Components/PersonalInfo";

import Procedures from "./Components/ProceduresTbl";
import Tests from "./Components/Tests";


const Insurance = () => {
  return (
    <div className="bg-bright_grey ">
      <BellTopBar label="Membership Details -John Doe"/>
      <PersonalInfo/>
      <Procedures/>
      <Tests/>
      <Drugs/>
    </div>
  );
};

export default Insurance;
  