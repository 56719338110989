import AddPatientDetails from "./AddPatientDetails";
import MorePatientDetails from "./MorePatientDetails";
import Upload from "./Upload";

const Forms = ({ step, setStep }) => {
  if (step === -1) {
    return <AddPatientDetails setStep={setStep} />;
  }
  if (step === 0) {
    return <MorePatientDetails setStep={setStep} />;
  }
  return <Upload setStep={setStep} />;
};

export default Forms;
