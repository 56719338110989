import React, { useRef, useState, useEffect, useContext } from "react";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import user_img from "./../../../../../../Assets/Images/User_Img.png";

import { BsFillPlusCircleFill } from "react-icons/bs";

import AddContext from "../AddContext";

import useApp from "../../../../../../Hooks/useApp";

import useAxios from "./../../../../../../Hooks/useAxios";

import { toTimeStamp } from "./../../../../../../Util/Time";

import { useNavigate } from "react-router-dom";

const Upload = () => {
  const [files, setFiles] = useState(null);
  const [submited, setSubmited] = useState(null);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const { setPic, pic, patient, setPatient, setStep } = useContext(AddContext);

  const { setModalMessage, setShowFormModal, setShowSuccessModal } = useApp();

  const doc = useRef();
  const { user } = useApp();

  const request = useAxios();

  function handleAdd() {
    doc.current.click();
    setSubmited(true);
  }

  useEffect(() => {
    if (files === null || submited === false) return;

    async function addDocument() {
      setImage(URL.createObjectURL(files[0]));
      setPic(files[0]);
    }
    addDocument();
  }, [submited, files]);

  useEffect(() => {
    if (pic === null) return;
    setImage(URL.createObjectURL(pic));
  }, []);

  async function submit() {
    if (files !== null) {
      setPic(files[0]);
    }

    let formData = new FormData();

    formData.append("pic", pic);
    formData.append("first_name", patient.first_name);
    formData.append("second_name", patient.second_name);
    formData.append("sir_name", patient.sir_name);
    formData.append("email", patient.email);
    formData.append("phone", patient.phone);
    if (patient.id_no !== "") {
      formData.append("id_no", parseInt(patient.id_no));
    }
    formData.append("dob", toTimeStamp(patient.dob));
    formData.append("nationality", patient.nationality);
    formData.append("gender", patient.gender);
    formData.append("occupation", patient.occupation);
    formData.append("religion", patient.religion);
    formData.append("district", patient.district);
    formData.append("ward", patient.ward);
    formData.append("receptionist_id", user.id);

    let res = await request({
      method: "POST",
      url: "patient",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    });

    if (res === "error") return;

    await setModalMessage("Patient added");
    await setShowSuccessModal(true);
    await setPatient(res);
    setStep((c) => c + 1);
  }

  return (
    <div>
      <div className="w-full h-full flex justify-center items-center">
        <div className=" bg-bright_grey p-8 rounded-2xl relative">
          <img
            src={image ? image : user_img}
            alt="user"
            style={{ maxWidth: "350px", maxHeight: "350px" }}
          />
          <span
            onClick={handleAdd}
            className=" text-5xl cursor-pointer absolute right-3 bottom-2 shadow-2xl bg-transparent rounded-full z-50 text-primary"
          >
            <BsFillPlusCircleFill />
          </span>
        </div>
        <input
          className=" hidden"
          ref={doc}
          type="file"
          id="myFile"
          name="filename"
          accept="image/png, image/jpeg"
          onChange={(e) => setFiles(e.target.files)}
        />
      </div>
      <BtnRounded mt={2} click={submit} className="btn">
        <span className=" font-pop-reg">Continue</span>
      </BtnRounded>
    </div>
  );
};

export default Upload;
