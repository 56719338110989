import Steppers from "../../../../../../Components/Steppers/Steppers";

import { FiUpload } from "react-icons/fi";

import { CgFileDocument } from "react-icons/cg";

const Steps = ({ currentStep, close }) => {
  return (
    <Steppers currentStep={currentStep} close={close}>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <CgFileDocument color="white" />
        <p>Add Patient Details</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <CgFileDocument color="white" />
        <p>More Patient Details</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FiUpload />
        <p>Upload Photo </p>
      </div>
    </Steppers>
  );
};

export default Steps;
