const HeaderBtn = ({ w, label }) => {
  return (
    <div
      className="flex   flex-row  font-DM-Med text-base"
      style={{ width: `${w}%`, color: "#8392AB" }}
    >
      <p className=" uppercase">{label}</p>
    </div>
  );
};

export default HeaderBtn;
