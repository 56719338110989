const TableText = ({ label, mt = 0 }) => {
  return (
    <p
      className=" font-DM-Reg text-base"
      style={{ color: "#455A64", marginTop: `${mt}em` }}
    >
      {label}
    </p>
  );
};

export default TableText;
