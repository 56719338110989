import TableComponent  from "./TableComponent";


const Procedures =()=>{
    return(
        <div className="w-[1137px] bg-white shadow-md rounded-lg m-auto mt-10 p-5" >
            <h2> Procedures</h2>
            <TableComponent/>
        </div>
    )
};

export default Procedures;