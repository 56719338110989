import React, { useEffect } from "react";

import Modal from "./BasicModal";

import { BsCheck2Square } from "react-icons/bs";

const SuccessModal = ({
  showSuccessModal,
  setShowSuccessModal,
  modal_message,
}) => {
  useEffect(() => {
    if (showSuccessModal === false) return;
    setTimeout(() => {
      setShowSuccessModal(false);
    }, 6000);
  }, [showSuccessModal]);

  return (
    <Modal showModal={showSuccessModal} setShowModal={setShowSuccessModal}>
      <div className=" p-2 ">
        <div className=" w-full flex flex-col items-center justify-center">
          <p className=" text-8xl animate-pulse text-primary">
            <BsCheck2Square />
          </p>
          <p className=" text-2xl mt-4 mb-4">{modal_message || "Success"}</p>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
