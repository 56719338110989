import React, { useEffect, useState } from "react";
import ViewPatient from "./components/ViewPatient";

import VisitLog from "./components/VisitLogs/Table";

const ViewPatients = () => {
  return (
    <div className=" w-full h-full ">
      <ViewPatient />
      <div className=" h-fit px-6  ">
        <VisitLog />
      </div>
      <div style={{ height: "5em" }}></div>
    </div>
  );
};

export default ViewPatients;
