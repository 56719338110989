import { TableRow, TableCell, TableText, Table } from "../../../../../../../Components/FinalTable";

const TableCp=({ data})=>{
    return(
        <div>
            { data.map((data, i)=>(
                 <Table>
                 <TableRow mt={10} key={i} >
                     <TableCell w={100 / 3}>
                         <TableText label={data.name}/>
                     </TableCell>
                     <TableCell w={100 / 3}>
                         <TableText label={data.regular}/>
                     </TableCell>
                     <TableCell w={100 / 3}>
                         <TableText label={data.scheme}/>
                     </TableCell>
                 </TableRow>
             </Table>

            ))}
        </div>
    )
};

export default TableCp;
