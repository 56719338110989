import Headertbl from "./Header";
import Infotbl from "./Infotbl";
import TableCp from "./Table";

const TableComponent =()=>{
    const Title = [
        {id:1, name:"Name"},
        { id:2, name: "Regular Cost"},
        { id:3, name: "Scheme cost"}
    ];

    const data = [
        {
            name:"x-Ray",
            regular:4000,
            scheme:5000
        },
        {
            name:"x-Ray",
            regular:4000,
            scheme:5000
        },
        {
            name:"x-Ray",
            regular:4000,
            scheme:5000
        },];


    return(
        <div>
            <Headertbl Titles={Title} />
            <TableCp data={data}/>
            <Infotbl data={data}/>
        </div>
    )
};
export default TableComponent;