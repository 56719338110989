import { AiFillFolderOpen } from "react-icons/ai";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const VisitLogs = ({ open = false }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "visit-logs-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Visit Logs"}
        open={open}
        active={location.pathname.includes("dashboard/visit-logs")}
        click={() => navigate("visit-logs")}
      >
        <AiFillFolderOpen />
      </BtnTemplate>
      <Tooltip id={id} txt1="View all patients" txt2="Visit Logs">
        <AiFillFolderOpen />
      </Tooltip>
    </div>
  );
};

export default VisitLogs;
