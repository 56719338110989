import React, { useEffect } from "react";
import BellTopBar from "../../../../Components/Bars/BellTopBar";
import CancelBar from "../../../../Components/Steppers/CancelBar";

import VisitTable from "./VisitStep";

const NextStep = () => {
  return (
    <div className=" w-full h-full flex flex-col  bg-ghost_white ">
      <BellTopBar label={`Next Step`} />
      <CancelBar />
      <div className="w-full  h-fit  px-5  flex flex-row justify-center  ">
        <VisitTable />
      </div>
    </div>
  );
};

export default NextStep;
