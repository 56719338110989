import React, { useEffect, useRef, useState } from "react";
import BasicModal from "./../../../../../Components/Modals/BasicModal";

import usePouch from "../../../../../Hooks/usePouch";

import SelectInput from "../../../../../Components/Inputs/SelectInput";

import TextArea from "../../../../../Components/Inputs/TextBox";

import useApp from "../../../../../Hooks/useApp";

import useAxios from "../../../../../Hooks/useAxios";

import useHandleFormError from "../../../../../Hooks/useHandleFormError";

import BtnBlock from "./../../../../../Components/Buttons/BtnBlock";

import SelecInput from "../../../../../Components/StateInput/SelectInput";

import { IoMdArrowForward } from "react-icons/io";
import usePouchCud from "../../../Hooks/usePouchCud";

const AddStep = ({
  id,
  showModal,
  setShowModal,
  setData,
  fetchSteps,
  space,
  team,
}) => {
  const [users, setUsers] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [spaces, setSpaces] = useState([]);
  const [show, setShow] = useState(false);

  const [showError, setShowError] = useState(0);

  const input = useRef({ form: {}, err: {} });

  const request = useAxios();

  const formErrors = useHandleFormError();

  const { user, setModalMessage, setShowSuccessModal } = useApp();

  async function submit() {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let dt = {
      visit_id: parseInt(id),
      space_id: parseInt(selectedSpace),
      is_high: input.current.form.is_high === "true" ? true : false,
      comments: input.current.form.comments ? input.current.form.comments : " ",
      step_created_by: user.id,
    };
    let obj = {
      ...(selectedUser && { user_id: parseInt(selectedUser) }),
      ...dt,
    };

    let res = await request({
      method: "POST",
      url: "hospital/step",
      data: obj,
    });

    if (res === "error") return;
    setModalMessage("Step Added!");
    setShowSuccessModal(true);
    setShowModal(false);
    await fetchSteps();
    setShowSuccessModal(false);
  }
  if (setShow === false) return;

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Add A Visit Step
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <SelecInput
            input={selectedSpace}
            inputKey="id"
            setInput={setSelectedSpace}
            showError={showError}
            mt={0.5}
            config={{
              required: true,
              firstDisabled: false,
              items: space,
              label: "Select Space",
            }}
          />

          <SelecInput
            input={selectedUser}
            inputKey="room_no"
            setInput={setSelectedUser}
            showError={showError}
            mt={0.5}
            config={{
              required: false,
              firstDisabled: false,
              items: team[selectedSpace] ? team[selectedSpace] : [],
              label: "Select User",
            }}
          />
          <SelectInput
            initial={false}
            input={input}
            inputKey="is_high"
            mt={0.5}
            config={{
              required: false,
              label: "Priority",
              firstDisabled: false,
              items: [
                { label: "Normal", value: false },
                { label: "High", value: true },
              ],
            }}
          />
          <TextArea
            initial={""}
            input={input}
            showError={showError}
            inputKey="comments"
            mt={0.5}
            config={{
              required: false,
              label: "Comments",
              placeholder: "",
              type: "text",
              inputType: "text",
              maxChar: 1000,
              minChar: 4,
            }}
          />
          <BtnBlock click={submit} mt={2.5} bg="primary" textColor="white">
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Continue</span>
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default AddStep;
