import React, { useContext } from "react";
import TRow from "./TRow";

import TContext from "./TContext";

const TBody = ({ data }) => {
  return (
    <>
      {data.map((doc) => (
        <TRow doc={doc} key={doc.id} />
      ))}
    </>
  );
};

export default TBody;
