import BasicModal from "./../../../../../Components/Modals/BasicModal";

const PendinInv = ({ doc, setShowModal, showModal }) => {
  //   return <h1 onClick={() => setShowModal(false)}>Hello World</h1>;

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className=" p-4">
        <ul className="text-xl">
          <li>
            <span className=" ">Pending Investigation: </span>
            <span className="">{doc.investigations.length}</span>
          </li>
          <li>
            <span className=" ">Pending Prescriptions: </span>
            {doc.prescriptions.length}
          </li>
          <li>
            <span className=" ">Pending Procedures: </span>
            {doc.procedures.length}
          </li>
        </ul>
      </div>
    </BasicModal>
  );
};

export default PendinInv;
