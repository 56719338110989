const longMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const convDate = (n) => {
  let d = new Date(n);

  return `${d.getDate()} ${shortMonthNames[d.getMonth()]} ${d.getFullYear()}`;
};

const convDate2 = (n) => {
  let d = new Date(n);

  return `${d.getFullYear()}-${toFullNo(d.getMonth() + 1)}-${toFullNo(
    d.getDate()
  )}`;
};

const convDate3 = (n) => {
  let d = new Date(n);

  return `${Days[d.getDay()]}, ${d.getDate()} ${
    longMonths[d.getMonth()]
  } ${d.getFullYear()}`;
};

const isoDate = (n) => {
  let d = new Date(n);

  return d.toISOString();
};

const toFullNo = (n) => {
  if (parseInt(n) < 10) {
    return `0${n}`;
  }
  return n;
};

const toTimeStamp = (d) => {
  const date = new Date(d);
  return date.getTime();
};

const timePeriodConversion = (diff) => {
  if (diff <= 60) {
    return "1 min";
  }

  if (diff / 60 < 60) {
    return `${Math.floor(diff / 60)} min`;
  }

  if (diff / 3600 < 60) {
    if (Math.floor(diff / 3600) === 1 || Math.floor(diff / 3600) === 0) {
      return `${Math.floor(diff / 3600)} hour `;
    }
    return `${Math.floor(diff / 3600)} hours `;
  }

  if (diff / 86400 < 32) {
    if (Math.floor(diff / 86400) === 1 || Math.floor(diff / 86400) === 0) {
      return `${Math.floor(diff / 86400)} day `;
    }
    return `${Math.floor(diff / 86400)} days`;
  }

  if (diff / 2629746 < 12) {
    let days = diff / 2629746;
    if (days <= 1) {
      return `${Math.floor(diff / 2629746)} month`;
    }
    return `${Math.floor(diff / 2629746)} months`;
  }
  let years = Math.floor(diff / 31556952);
  if (years <= 1) {
    return `${years} year`;
  }
  return `${Math.floor(diff / 31556952)} years`;
};

export { convDate, convDate2, convDate3, toTimeStamp, isoDate };
