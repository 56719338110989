const toTZS = (n) => {
  try {
    return n.toLocaleString() + " TZS";
  } catch (e) {
    console.log(e);
    return "";
  }
};

export { toTZS };
