import React from 'react'
import PatientsPiechart from './PatientsPiechart'

export default function Requests({requests}) {
  return (
    <div className='w-full px-4 h-96 bg-white rounded-2xl flex flex-col '>
      <div className="flex justify-between items-center align-middle">
        <h1 className="font-bold text-base px-6 py-3">Requests</h1>
      </div>
      <div className='flex justify-center' >
        <PatientsPiechart requests={requests} />
        <div className="flex w-11/12 flex-col justify-start text-xs text-gray-500 mt-10 mr-2">
          <h1 className="text-sm"><div className='w-2 h-2 bg-[#7f56da] rounded-full inline-block'></div> Approved ({requests.approved && requests.approved})</h1>
          <h1 className="text-sm"><div className='w-2 h-2 bg-[#e7defa] rounded-full inline-block'></div> Canceled ({requests.canceled && requests.canceled})</h1>
          <h1 className="text-sm"> <div className='w-2 h-2 bg-[#9e77ec] rounded-full inline-block'></div> Pending ({requests.pending && requests.pending})</h1>
        </div>
      </div>
    </div>
  )
}
