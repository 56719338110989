import { colors } from "./../../Util/colors";

import { GiUnplugged } from "react-icons/gi";

const NetworkBadge = ({ textColor = "primary" }) => {
  return (
    <div
      style={{
        paddingVertical: 3,

        color: colors[textColor],
      }}
      className="relative h-fit rounded-md w-fit cursor-pointer flex flex-row justify-center items-center  px-2 font-pop-reg  text-base"
    >
      {/* <div className="absolute  top-1 right-1 -mr-1 -mt-1 w-2 h-2 rounded-full bg-pink animate-ping"></div> */}
      <div className=" text-rose-300 text-2xl animate-ping ">
        <GiUnplugged />
      </div>
    </div>
  );
};

export default NetworkBadge;
