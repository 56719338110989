import React, { useState, useEffect, useRef, useContext } from "react";

import TextInput from "../../../../../../Components/Inputs/TextInput";

import NumberInput from "../../../../../../Components/Inputs/NumberInput";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import StateSelect from "../../../../../../Components/StateInput/SelectInput";

import usePouch from "../../../../../../Hooks/usePouch";

import usePouchCud from "../../../../Hooks/usePouchCud";

import { useParams, useNavigate } from "react-router-dom";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import useAxios from "../../../../../../Hooks/useAxios";

import useApp from "../../../../../../Hooks/useApp";

function FormSection({}) {
  const input = useRef({ form: {}, err: {} });

  const { setShowFormModal, setModalMessage } = useApp();

  const [changes, setChanges] = useState(0);

  const [visit, setVisit] = useState("Normal");
  const [visits, setVisits] = useState([]);
  const [visitObj, setVisitObj] = useState({});

  const [default_payment, setDefaultPayment] = useState("Cash");

  const [insurance, setInsurance] = useState("");

  const [insurances, setInsurances] = useState([]);

  const [AllSchemes, setAllSchemes] = useState({});

  const [scheme, setSheme] = useState("");

  const [showError, setShowError] = useState(0);

  const formErrors = useHandleFormError();

  const { user } = useApp();

  const request = useAxios();

  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    async function init() {
      let res = await request({
        method: "GET",
        url: "hospital/checkin/params",
      });

      if (res === "error") {
        return;
      }

      let ins = [];

      let visits = [
        {
          label: "Select Visit Type",
          value: "",
        },
      ];

      let visitObj = {};

      for (let doc of res.visit_types) {
        visits.push({ label: doc.name, value: doc.id });
        visitObj[doc.id] = doc.name;
      }

      let shemes = {};

      for (let insurance of res.insurances) {
        let ar = insurance.insurance_scheme.map((doc) => ({
          label: doc.name,
          value: doc.id,
        }));
        shemes[insurance.id] = [{ label: "Select Scheme", value: "" }, ...ar];
        ins.push({
          label: `${insurance.company_name}: ${insurance.name}`,
          value: insurance.id,
        });
      }

      setAllSchemes(shemes);
      setVisits(visits);
      setVisitObj(visitObj);
      setInsurances([{ label: "Select Insurance", value: "" }, ...ins]);
    }

    init();
  }, []);

  async function submit() {
    const data = {
      default_payment_method: default_payment,
      visit_type_id: parseInt(visit),
      patient_id: parseInt(id),
      checked_in_by: user.id,
    };

    if (default_payment === "Insurance") {
      await setShowError(showError + 1);
      if (formErrors({ input })) return;

      if (scheme === "") {
        setModalMessage("! Select insurance scheme");
        setShowFormModal(true);
        return;
      }

      if (parseInt(input.current.form.insurance_balance) === 0) {
        setModalMessage("! Check insurance balance");
        setShowFormModal(true);
        return;
      }

      data.member_no = input.current.form.member_no;
      data.insurance_balance = parseInt(input.current.form.insurance_balance);
      data.insurance_id = parseInt(insurance);
      data.scheme_id = parseInt(scheme);
    }

    if (visitObj[visit] === "Referral") {
      await setShowError(showError + 1);
      if (formErrors({ input })) return;
      if (input.current.form.referral_no === "") {
        setModalMessage("! Enter referral number");
        setShowFormModal(true);
        return;
      }
      data.referral_no = input.current.form.referral_no;
    }

    let res = await request({
      method: "POST",
      url: "hospital/checkin",
      data: data,
    });

    if (res === "error") return;
    navigate(`/dashboard/next-step/${res.visit.id}`);
    return;
  }

  useEffect(() => {
    if (insurance === "") return;
    setSheme("");
  }, [insurance]);

  return (
    <div
      className="w-full h-full  bg-white px-10 py-3  rounded-tr-lg rounded-br-lg  overflow-y-auto"
      style={{ height: "90%" }}
    >
      <p className="font-bold text-xl ">Check-In</p>
      <StateSelect
        showError={showError}
        input={default_payment}
        setInput={setDefaultPayment}
        inputKey="payment"
        config={{
          disabled: false,
          firstDisabled: false,
          items: [
            {
              label: "Cash",
              value: "Cash",
            },
            {
              label: "Insurance",
              value: "Insurance",
            },
          ],
          label: "Payment",
          required: true,
        }}
      />
      {default_payment === "Insurance" ? (
        <>
          <StateSelect
            showError={showError}
            input={insurance}
            setInput={setInsurance}
            config={{
              disabled: false,
              firstDisabled: false,
              items: insurances,
              label: "Select Insurance",
              required: true,
            }}
          />

          <StateSelect
            showError={showError}
            input={scheme}
            setInput={setSheme}
            config={{
              disabled: false,
              firstDisabled: false,
              items: AllSchemes[insurance] || [],
              label: "Select Scheme",
              required: true,
            }}
          />
          <NumberInput
            initial={0}
            input={input}
            showError={showError}
            inputKey="insurance_balance"
            mt={0.5}
            config={{
              required: true,
              label: "Insurance Balance",
              placeholder: "3455",
              max: 9999999999999,
              min: 0,
            }}
          />

          <TextInput
            initial=""
            input={input}
            showError={showError}
            inputKey="member_no"
            mt={0.5}
            config={{
              required: true,
              label: "Member Id",
              placeholder: "345546453323",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <p className="text-sm underline text-gray-400/90 underline-offset-4 font-semibold cursor-pointer mb-5">
            View membership details ...
          </p>
        </>
      ) : null}

      <StateSelect
        showError={showError}
        input={visit}
        inputKey="visit_type_id"
        setInput={setVisit}
        config={{
          disabled: false,
          firstDisabled: false,
          items: visits,
          label: "Visit Type",
          required: true,
        }}
      />

      {visitObj[visit] === "Referral" ? (
        <>
          <TextInput
            initial=""
            input={input}
            showError={showError}
            inputKey="referral_no"
            mt={0.5}
            config={{
              required: true,
              label: "Referral Number",
              placeholder: " 345546453323",
              type: "text",
              maxChar: 250,
              minChar: 4,
            }}
          />
        </>
      ) : null}

      <BtnRounded mt={2} mb={4} click={submit} className="btn">
        <span className=" font-pop-reg">Next Steps</span>
      </BtnRounded>

      <div style={{ height: "20px" }}></div>
    </div>
  );
}

export default FormSection;
