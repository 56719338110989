import React, { useRef, useContext, useState, useEffect } from "react";

import FilterModal from "../../../../../../Components/Modals/FilterModal";

import StateInput from "../../../../../../Components/StateInput/SelectInput";

import DatePicker from "../../../../../../Components/Inputs/DatePicker";

import { convDate2, isoDate } from "../../../../../../Util/Time";

import AllContext from "../../AllContext";

const TblFilter = ({ showFilter, setShowFilter }) => {
  const input = useRef({ form: { minDate: "", maxDate: "" }, err: {} });

  const { handleFilterQuery } = useContext(AllContext);

  const [payment, setPayment] = useState("");

  async function applyFilter() {
    let filters = {};
    let to_filter = false;

    let ar = [
      ["filter_payment_method", payment],
      [
        "filter_start_date",
        input.current.form.minDate === ""
          ? ""
          : isoDate(input.current.form.minDate),
      ],
      [
        "filter_end_date",
        input.current.form.maxDate === ""
          ? ""
          : isoDate(input.current.form.maxDate),
      ],
    ];

    for (let a of ar) {
      if (a[1] === "") {
        continue;
      }
      to_filter = true;
      filters[a[0]] = isNaN(a[1]) ? a[1] : parseInt(a[1]);
    }

    if (to_filter) {
      handleFilterQuery(filters);
      setShowFilter(false);
      return;
    }
  }

  return (
    <FilterModal
      showFilter={showFilter}
      setShowFilter={setShowFilter}
      apply={applyFilter}
    >
      <div>
        <h2 className=" font-DM-Bold text-xl my-3 text-cadet_grey">
          PAYMENT METHOD
        </h2>
        <StateInput
          input={payment}
          setInput={setPayment}
          config={{
            required: false,
            firstDisabled: false,
            items: [
              { label: "All", value: "" },
              { label: "Insurance", value: "Insurance" },
              { label: "Cash", value: "Cash" },
            ],
            label: "Select Payment Method",
          }}
        />
      </div>

      <div className=" mt-4">
        <h2 className=" font-DM-Bold text-xl text-cadet_grey ">
          Check-in Date
        </h2>
        <DatePicker
          initial={""}
          input={input}
          inputKey="minDate"
          mt={0}
          mb={0}
          config={{
            required: false,
            disabled: false,
            max: convDate2(Date.now()),
            label: "Start date",
          }}
        />
        <DatePicker
          initial={""}
          input={input}
          inputKey="maxDate"
          mt={-2.5}
          mb={0}
          config={{
            required: false,
            disabled: false,
            max: convDate2(Date.now()),
            label: "End date",
          }}
        />
      </div>
    </FilterModal>
  );
};

export default TblFilter;
