import React, { useEffect, useRef } from "react";

import Fuse from "fuse.js";

import PouchDB from "pouchdb";
import pouchDbFind from "pouchdb-find";

import { toast } from "react-toastify";

PouchDB.plugin(pouchDbFind);

const useFuzzySearch = ({
  db_name,
  input,
  options,
  limit,
  setData,
  setShowPagination,
  setIsFiltered,
  setTableIsLoading,
  fixed = {},
}) => {
  const en = useRef(null);

  const db = new PouchDB(db_name);

  db.createIndex({
    index: { fields: ["id"] },
  });

  useEffect(() => {
    if (input === "") {
      setShowPagination(true);
      return;
    }
    if (input.length < 3) {
      en.current = null;
      return;
    }

    const searchData = async () => {
      try {
        if (input.length < 3) {
          return;
        }
        setTableIsLoading(true);
        setShowPagination(false);
        setIsFiltered(true);
        let docs = [];
        while (true) {
          let data = await getDocs();
          if (data.theEnd) {
            break;
          }
          let fuse = new Fuse(data.data, options);
          let result = await fuse.search(input);
          docs = [...docs, ...result];
        }

        let fuse = new Fuse(
          docs.map((doc) => doc.item),
          options
        );
        let result = await fuse.search(input);
        let newResult = result.map((doc) => doc.item);

        if (newResult.length > limit) {
          en.current = null;
          docs.current = [];
          setData(newResult.slice(0, limit));
          return;
        }

        en.current = null;
        docs.current = [];
        setData(newResult);
      } catch (e) {
        setData([]);
      } finally {
        en.current = null;
        setTimeout(() => {
          setTableIsLoading(false);
        }, 5000);
      }
    };
    searchData();
  }, [input]);

  const getDocs = async () => {
    try {
      const limit = 100;
      let theEnd = false;
      let data = await db.find({
        selector: { id: { $gte: en.current }, ...fixed },
        sort: ["id"],
        limit: limit,
      });

      let docs = data.docs;

      if (docs.length < limit) {
        theEnd = true;
      } else {
        theEnd = false;
      }

      if (docs.length === 0) {
        en.current = null;
        theEnd = true;
        return { theEnd: theEnd, data: docs };
      }
      en.current = docs[docs.length - 1].id;
      return { theEnd: theEnd, data: docs };
    } catch (e) {
      en.current = null;
      return { theEnd: true, data: [] };
    }
  };
};

export default useFuzzySearch;
