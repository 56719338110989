import logo from "../../../Assets/Images/mkombozilogo.png";

const InfoSection = ({ mt = 0, mb = 0 }) => {
  return (
    <div
      className="w-full flex flex-col items-center"
      style={{ marginTop: mt, marginBottom: mb }}
    >
      <img id="logo" alt="logo" src={logo} />
      <p className=" font-DM-Reg text-2xl text-black">Welcome Back</p>
      <p style={{ color: "#8392AB" }} className=" mt-7  font-DM-Reg text-xl">
        Enter your email and password to sign in
      </p>
    </div>
  );
};

export default InfoSection;
