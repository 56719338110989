import React, { useState, useEffect, useContext } from "react";

import {
  TableHeaderRow,
  TableHeaderBtn,
} from "../../../../../../../Components/FinalTable";

const THead = () => {
  return (
    <TableHeaderRow>
      <TableHeaderBtn w={95 / 5} label="CHECKED IN BY" />
      <TableHeaderBtn w={95 / 5} label="CHECK-IN DATE" />
      <TableHeaderBtn w={95 / 5} label="VISIT ID" />
      <TableHeaderBtn w={95 / 5} label="VISIT TYPE" />
      <TableHeaderBtn w={95 / 5} label="PAYMENT" />
      <TableHeaderBtn w={95 / 5} label="STATUS" />
      <div style={{ width: "5%" }}></div>
    </TableHeaderRow>
  );
};

export default THead;
