import React, { useContext, useState, useRef } from "react";
import TextInput from "../../../../../../Components/Inputs/TextInput";

import NumberInput from "../../../../../../Components/Inputs/NumberInput";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import AddContext from "../AddContext";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

const PatientDetails = () => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);

  const { patient, setPatient, setStep } = useContext(AddContext);

  const formErrors = useHandleFormError();

  async function submit() {
    await setShowError(showError + 1);

    if (formErrors({ input })) return;

    await setPatient({ ...patient, ...input.current.form });
    setStep((c) => c + 1);
  }

  return (
    <div
      className=" m-auto p-10 shadow-md rounded-lg mt-5 bg-white"
      style={{ width: "60%" }}
    >
      <div className="w-full flex gap-x-6">
        <div className="flex-1">
          <TextInput
            initial={patient.first_name || ""}
            input={input}
            showError={showError}
            inputKey="first_name"
            mt={0.5}
            config={{
              required: true,
              label: "First Name",
              placeholder: "John",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
            initial={patient.second_name || ""}
            input={input}
            showError={showError}
            inputKey="second_name"
            mt={0.5}
            config={{
              required: true,
              label: "Second Name",
              placeholder: "Mwangi",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
            initial={patient.sir_name || ""}
            input={input}
            showError={showError}
            inputKey="sir_name"
            mt={0.5}
            config={{
              required: true,
              label: "Sir Name",
              placeholder: "Mazera",
              maxChar: 250,
              minChar: 3,
            }}
          />
        </div>
        <div className="flex-1">
          <TextInput
            initial={patient.email || ""}
            input={input}
            showError={showError}
            inputKey="email"
            mt={0.5}
            config={{
              required: false,
              label: "Email",
              placeholder: "Eg. johndoe@gmail.com",
              inputType: "email",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <NumberInput
            initial={patient.phone || ""}
            input={input}
            showError={showError}
            inputKey="phone"
            mt={0.5}
            config={{
              required: false,
              label: "Phone",
              placeholder: "Enter phone number ",
              maxChar: 13,
              minChar: 6,
            }}
          />
          <NumberInput
            initial={patient.id_no || ""}
            input={input}
            showError={showError}
            inputKey="id_no"
            mt={0.5}
            config={{
              required: false,
              label: "NIDA",
              placeholder: "345632212 ",
              maxChar: 13,
              minChar: 6,
            }}
          />
        </div>
      </div>

      <BtnRounded click={submit}>Continue</BtnRounded>
    </div>
  );
};

export default PatientDetails;
