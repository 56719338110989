import React, { useState, useEffect } from "react";

import { Table } from "../../../../../../../Components/FinalTable";

import THead from "./THead";

import TBody from "./TBody";

import TInfo from "./TInfo";

import TContext from "./TContext";

import { useNavigate, useParams } from "react-router-dom";
import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";

import useAxios from "./../../../../../../../Hooks/useAxios";

import { ThreeDots } from "react-loader-spinner";

import { colors } from "./../../../../../../../Util/colors";

import useApp from "../../../../../../../Hooks/useApp";

const TableAll = ({ doc }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [isFiltered, setIsFiltered] = useState(false);

  const { setModalMessage, setShowFormModal } = useApp();

  const navigate = useNavigate();

  const request = useAxios();

  const { id } = useParams();

  async function getVisits(page = 1, limit = 10) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "patient/visit-logs",
      params: {
        patient_id: id,
        page: page,
        limit: limit,
        sort_field: "created_at",
        sort_order: "desc",
      },
    });

    if (res === "error") {
      return;
    }

    setData(res.docs);

    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.setHasPrevPage);
    setTableIsLoading(false);
  }

  useEffect(() => {
    getVisits(page, limit);
  }, []);

  async function handleCheckIn() {
    let res = await request({
      method: "GET",
      url: "patient/is-checked-in",
      params: {
        patient_id: id,
      },
    });

    if (res === "error") return;

    if (res.isCheckedIn) {
      setModalMessage(`Patient is already checked in`);

      setShowFormModal(true);

      return;
    }

    navigate(`/dashboard/patients/check-in/${id}`);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getVisits(page + 1, limit);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getVisits(page + 1, limit);
    }
  }

  return (
    <TContext.Provider
      value={{
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext: handleNext,
        handlePrev: handlePrev,
        handleHeaderQuery: () => {},
        handleFilterQuery: () => {},
        isFiltered,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: () => {},
      }}
    >
      <Table>
        <div className="flex justify-end gap-x-4">
          <ThreeDots
            height="40"
            width="40"
            radius="9"
            color={colors.primary_accent}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={tableIsLoading}
          />
          <BtnRounded click={handleCheckIn}>
            <p>Check In</p>
          </BtnRounded>
        </div>
        <THead />
        <TBody data={data} />
        <TInfo />
      </Table>
    </TContext.Provider>
  );
};

export default TableAll;
