import React, { useState, useEffect, useContext } from "react";

import { TableHeaderRow } from "../../../../../../../Components/FinalTable";

import HeaderBtnSort from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import AllContext from "../../AllContext";

const genders = ["Male", "Female", "Other"];

const THead = () => {
  const { handleHeaderQuery, showLive, handleFilterQuery, setIsFiltered } =
    useContext(AllContext);

  const [name, setName] = useState(true);
  const [email, setEmail] = useState(true);
  const [phone, setPhone] = useState(true);
  const [id_no, setIdNo] = useState(true);
  const [dob, setDob] = useState(true);
  const [gender, setGender] = useState(0);
  const [genderSort, setGenderSort] = useState(true);

  function handleNameSort() {
    if (showLive) {
      handleHeaderQuery("first_name", name ? "asc" : "desc");
      setName(!name);
      return;
    }

    setIsFiltered(false);
    const index = ["first_name"];
    const sort = [{ first_name: name ? "asc" : "desc" }];
    const selector = { first_name: { $gte: null } };

    setName(!name);

    handleHeaderQuery(index, sort, selector);
  }

  function handleEmailSort() {
    if (showLive) {
      handleHeaderQuery("email", email ? "asc" : "desc");
      setEmail(!email);
      return;
    }

    setIsFiltered(false);
    const index = ["email"];
    const sort = [{ email: email ? "asc" : "desc" }];
    const selector = { email: { $gte: null } };

    setEmail(!email);

    handleHeaderQuery(index, sort, selector);
  }

  function handlePhoneSort() {
    if (showLive) {
      handleHeaderQuery("phone", phone ? "asc" : "desc");
      setPhone(!phone);
      return;
    }

    setIsFiltered(false);
    const index = ["phone"];
    const sort = [{ phone: phone ? "asc" : "desc" }];
    const selector = { phone: { $gte: null } };

    setPhone(!phone);

    handleHeaderQuery(index, sort, selector);
  }

  function handleIdSort() {
    if (showLive) {
      handleHeaderQuery("id_no", id_no ? "asc" : "desc");
      setIdNo(!id_no);
      return;
    }

    setIsFiltered(false);
    const index = ["id_no"];
    const sort = [{ id_no: id_no ? "asc" : "desc" }];
    const selector = { id_no: { $gte: null } };

    setIdNo(!id_no);

    handleHeaderQuery(index, sort, selector);
  }

  function handleDoBSort() {
    if (showLive) {
      handleHeaderQuery("id_no", dob ? "asc" : "desc");
      setDob(!dob);
      return;
    }

    setIsFiltered(false);
    const index = ["dob"];
    const sort = [{ dob: dob ? "asc" : "desc" }];
    const selector = { dob: { $gte: null } };

    setDob(!dob);

    handleHeaderQuery(index, sort, selector);
  }

  function handleGenderFilter() {
    if (showLive) {
      handleHeaderQuery("gender", genderSort ? "asc" : "desc");
      setGenderSort(!genderSort);
      return;
    }

    const index = ["created_at"];
    const sort = [{ created_at: "desc" }];
    const selector = {
      created_at: { $gte: null },
    };

    const secondarySelector = {
      gender: {
        $eq: gender > genders.length - 1 ? genders[0] : genders[gender],
      },
    };

    if (gender > genders.length - 1) {
      setGender(0);
    } else {
      setGender((c) => c + 1);
    }
    handleFilterQuery(index, sort, selector, secondarySelector);

    setIsFiltered(true);
  }

  return (
    <TableHeaderRow>
      <HeaderBtnSort w={90 / 6} label="NAME" click={handleNameSort} />
      <HeaderBtnSort w={90 / 6} label="EMAIL" click={handleEmailSort} />
      <HeaderBtnSort w={90 / 6} label="PHONE" click={handlePhoneSort} />
      <HeaderBtnSort w={90 / 6} label="NIDA" click={handleIdSort} />
      <HeaderBtnSort w={90 / 6} label="DOB" click={handleDoBSort} />
      <HeaderBtnSort w={90 / 6} label="GENDER" click={handleGenderFilter} />
      <div style={{ width: "10%" }}></div>
    </TableHeaderRow>
  );
};

export default THead;
