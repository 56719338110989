import React, { useContext } from "react";
import TContext from "./TContext";

import TRow from "./TRow";

const TBody = () => {
  const { data } = useContext(TContext);

  return (
    <>
      {data.map((doc) => (
        <TRow key={doc._id || doc.id} doc={doc} />
      ))}
    </>
  );
};

export default TBody;
