import React, { useRef, useEffect, useState } from "react";

import usePouchCud from "./usePouchCud";

import PouchDB from "pouchdb";
import pouchDbFind from "pouchdb-find";

import useAxios from "./../../../Hooks/useAxios2";

import useApp from "./../../../Hooks/useApp";

import usePouch from "../../../Hooks/usePouch";

import useTables from "./useTables";

PouchDB.plugin(pouchDbFind);

const useSync = (tables) => {
  const { setIsOnline } = useApp();

  const tbs = useTables();

  const { create, update, deleteDoc } = usePouchCud();

  const request = useAxios({ showLoading: false });

  const { clearData2 } = usePouch();
  const en = useRef(null);

  useEffect(() => {
    //console.log("useSync");

    async function init() {
      try {
        let res = await request({
          method: "GET",
          baseUrl: "https://prod.api.mkombozihealthcare.co.tz",
          url: "",
        });

        if (res === "error") {
          setIsOnline(false);
          return;
        }

        setIsOnline(true);
        clearData2();

        for (let table of tbs) {
          let { url, params, limit, name } = table;
          let res = await request({
            method: "GET",
            url: url,
            params: params,
            limit: limit,
          });

          if (res === "error") continue;

          if (res.docs) {
            for (let doc of res.docs) {
              await create({ name, doc });
            }
            continue;
          }
          // console.log(res);
          await create({
            name,
            doc: res.id ? res : { ...res, id: Date.now() },
          });
        }
      } catch (e) {}
    }
    init();
  }, []);

  const incSync = (name) => {
    name = name.toLowerCase();
    let capName = name[0].toUpperCase() + name.slice(1, name.length);
    let table = tables[name];
    tables[`set${capName}`]({ ...table, sync: table.sync + 1 || 1 });
  };

  const inChanges = (name) => {
    name = name.toLowerCase();
    let capName = name[0].toUpperCase() + name.slice(1, name.length);
    let table = tables[name];
    tables[`set${capName}`]({ ...table, changes: table.changes + 1 || 1 });
  };

  const resetChanges = (name) => {
    name = name.toLowerCase();
    let capName = name[0].toUpperCase() + name.slice(1, name.length);
    let table = tables[name];
    tables[`set${capName}`]({ ...table, changes: 0 });
  };

  return {
    incSync,
    inChanges,
    resetChanges,
  };
};

export default useSync;
