import React, { useState, useEffect } from "react";
import BellTopBar from "../../../../../Components/Bars/BellTopBar";
import CancelBar from "../../../../../Components/Steppers/CancelBar";

import usePouch from "./../../../../../Hooks/usePouch";

import { useParams, useNavigate } from "react-router-dom";

import useAxios from "../../../../../Hooks/useAxios";

const ViewVisitLog = () => {
  const { id } = useParams();

  const request = useAxios();

  const [steps, setSteps] = useState([]);
  const [visit, setVisit] = useState({});
  const [patient, setPatient] = useState({});
  const [receptionist, setReceptionist] = useState({});

  const [step, setStep] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    async function initialize() {
      let res = await request({
        method: "GET",
        url: `hospital/step/${id}`,
      });

      if (res === "error") {
        //navigate("/dashboard/patients");
        return;
      }

      setSteps(res.steps);
      setVisit(res.visit);
      setPatient(res.patient);
      setReceptionist(res.receptionist);
    }
    initialize();
  }, []);

  return (
    <div className="bg-ghost_white h-full w-full">
      <BellTopBar
        label={`Log #vi-${id} ${patient.first_name}  ${patient.second_name}`}
      />
      <CancelBar />
      <div className="w-full px-3 mt-2 flex gap-x-2">
        <div></div>
        <div className=" flex"></div>
      </div>
    </div>
  );
};

export default ViewVisitLog;
