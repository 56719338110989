import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';

const COLORS = ['#7f56da', '#e7defa', '#9e77ec'];


export default function PatientsPiechart({requests}) {

  const data = [
    { name: 'Group A', value: requests.approved },
    { name: 'Group B', value: requests.canceled },
    { name: 'Group C', value: requests.pending },
  ];

  return (
    <div className=" w-full">
      <PieChart width={300} height={300} >
        <Pie
          data={data}
          cx={125}
          cy={150}
          innerRadius={55}
          outerRadius={125}
          fill="#8884d8"
          paddingAngle={0}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </div>
  )
}
