import Headertbl from "./TableComponent/Header";
import Infotbl from "./TableComponent/Infotbl";
import TableCp from "./TableComponent/Table";

const Drugs =()=>{
    const Title = [
        {id:1, name:"Name"},
        { id:2, name: "Regular Cost"},
        { id:3, name: "Scheme cost"}
    ];

    const data = [
        {
            name:"x-Ray",
            regular:4000,
            scheme:5000
        },
        {
            name:"x-Ray",
            regular:4000,
            scheme:5000
        },
        {
            name:"x-Ray",
            regular:4000,
            scheme:5000
        },
    ];

    return(
        <div className="w-[1137px] bg-white shadow-md rounded-lg m-auto mt-10 p-5">
            <h2>Drugs</h2>
            <Headertbl Titles={Title}/>
            <TableCp data={data}/>
            <Infotbl data={data}/>
        </div>
    )
};

export default Drugs;