import { FaUserInjured } from "react-icons/fa";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const Patients = ({ open }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "patients-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Patients"}
        open={open}
        active={location.pathname.includes("dashboard/patients")}
        click={() => navigate("patients")}
      >
        <FaUserInjured />
      </BtnTemplate>
      <Tooltip id={id} txt1="All Patients" txt2="In one spot">
        <FaUserInjured />
      </Tooltip>
    </div>
  );
};

export default Patients;
