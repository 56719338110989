import Lab from "./components/Lab";
import Navbar from "./components/Navbar";
import Patients from "./components/Patients";
import Pharmacy from "./components/Pharmacy";
import Queue from "./components/Queue";
import Triage from "./components/Triage";
import PatientsChart from "./components/PatientsChart";
import Requests from "./components/Requests";
import RecentRequests from "./components/RecentRequests";
import { useEffect, useState } from "react";
import useAxios from "../../../../Hooks/useAxios";

import usePouch from "./../../../../Hooks/usePouch";

const Summary = () => {
  const [patients, setPatients] = useState(0);
  const [triage_queue, settriage_queue] = useState(0);
  const [pharmacy_queue, setpharmacy_queue] = useState(0);
  const [lab_queue, setlab_queue] = useState(0);
  const [requests, setRequests] = useState("");
  const [recentRequests, setRecentRequests] = useState([]);
  const [mVisits, setMVisits] = useState([]);

  const { findAll2 } = usePouch();

  const request = useAxios();

  async function getInitialData() {
    let res = await request({
      method: "GET",
      url: "hospital/analytics/reception",
      network_error: true,
    });

    // let other = await findAll2({ db: "receptionist_data", index: ["_id"] });
    // console.log(other);
    // if (res === "network-error") {
    //   let other = await findAll2({ db: "receptionist_data", index: ["_id"] });
    //   if (other.length === 0) return;
    //   console.log(other);
    //   res = other[0];
    // }

    if (res === "error") {
      return;
    }

    setRecentRequests(res.recent_requests);
    setRequests(res.requests);
    settriage_queue(res.triage_queue);
    setpharmacy_queue(res.pharmacy_queue);
    setPatients(res.patients);
    setMVisits(res.patients_monthly);
    setlab_queue(res.lab_queue);
  }

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div className="w-full  bg-ghost_white">
      <div className="h-full px-10 ">
        <Navbar />
        <div className="flex justify-between lg:gap-x-10 mb-10 pt-8">
          <Patients patients={patients} />
          <Triage triage_queue={triage_queue} />
          <Lab lab_queue={lab_queue} />
          <Pharmacy pharmacy_queue={pharmacy_queue} />
        </div>
        <div className="flex justify-between mb-10 w-full">
          <div className="w-7/12">
            <PatientsChart mVisits={mVisits} />
          </div>
          <div className="5/12 mx-auto">
            <Requests requests={requests} />
          </div>
        </div>
        <div className="flex justify-between lg:gap-x-10 mb-10 w-full">
          <div className="w-7/12">
            <Queue />
          </div>
          <div className="w-5/12">
            <RecentRequests recentRequests={recentRequests} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
