import AddPatientDetails from './AddPatientDetails';
import MorePatientDetails from  './MorePatientDetails';
import NextOfKin from './NextOfKin';
import Upload from './Upload';

const Forms = ({ step, setStep }) => {
  if (step === -1) {
    return <AddPatientDetails setStep={setStep} />;
  }
  if (step === 0) {
    return <MorePatientDetails setStep={setStep} />;
  }
  if (step === 1) {
    return <Upload setStep={setStep} />;
  }

  return <NextOfKin  />;
};

export default Forms;