import React, { useState, useContext } from "react";
import BtnPlain from "../../../../../Components/Buttons/BtnPlain";
import { ActionBtn } from "../../../../../Components/FinalTable";

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

import { AiFillEye } from "react-icons/ai";

import ViewStep from "./../Modals/ViewStep";

import EditStep from "./../Modals/EditStep";

import { useParams } from "react-router-dom";

import TContext from "./TContext";
import useApp from "../../../../../Hooks/useApp";
import useAxios from "../../../../../Hooks/useAxios";

import { IoMdLocate } from "react-icons/io";

const TableAction = ({ doc }) => {
  const [showView, setShowView] = useState(false);

  const [showEdit, setShowEdit] = useState(false);

  const [hide, setHide] = useState(false);

  const { visit } = useParams();

  const { data, setData, fetchSteps } = useContext(TContext);
  const request = useAxios();
  const { setShowSuccessModal, setModalMessage, user } = useApp();

  const handleDelete = async () => {
    let res = await request({
      method: "DELETE",
      url: `hospital/step/${doc.id}`,
    });

    if (res !== "error") {
      setModalMessage("Step Deleted!");
      setShowSuccessModal(true);
      await fetchSteps();
    }
  };
  async function handleRelocate() {
    let res = await request({
      method: "POST",
      url: "hospital/step/reallocate",
      data: {
        step_id: parseInt(doc.id),
        user_id: parseInt(user.id),
      },
    });

    if (res === "error") return;
    await fetchSteps();
    setModalMessage("Patient Relocated!");
    setShowSuccessModal(true);
  }

  return (
    <div className="flex-1">
      {/* <TableHorizontalActionBtn /> */}
      <ActionBtn hide={hide} showNext={false} next={() => {}}>
        <div className=" gap-y-2 flex flex-col text-sm">
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => setShowView(true)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-black">
                <AiFillEye />
              </p>
              <p className=" ">View</p>
            </div>
          </BtnPlain>
          <ViewStep doc={doc} showModal={showView} setShowModal={setShowView} />
          {doc.currently_at === true || doc.is_complete === true ? null : (
            <>
              <BtnPlain
                activeBgColor="white"
                bg="white"
                click={() => setShowEdit(true)}
              >
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                  <p className="  text-black">
                    <FaEdit />
                  </p>
                  <p className=" ">Edit</p>
                </div>
              </BtnPlain>
              <BtnPlain activeBgColor="white" bg="white" click={handleRelocate}>
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                  <p className="  text-black">
                    <IoMdLocate />
                  </p>
                  <p className=" ">Rellocate</p>
                </div>
              </BtnPlain>
              <BtnPlain activeBgColor="white" bg="white" click={handleDelete}>
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                  <p className="  text-primary">
                    <MdDelete />
                  </p>
                  <p className=" ">Delete</p>
                </div>
              </BtnPlain>

              <EditStep
                id={visit}
                doc={doc}
                showModal={showEdit}
                setShowModal={setShowEdit}
                data={data}
                setData={setData}
                fetchSteps={fetchSteps}
              />
            </>
          )}
        </div>
      </ActionBtn>
    </div>
  );
};

export default TableAction;
