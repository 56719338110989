import { useState } from "react";

import FormScreen from "../../../../../Components/Templates/FormScreen";

import Forms from "./Components/Forms";

import FormLabel from "../../../../../Components/Label/FormLabel";

import { MdSick } from "react-icons/md";

import Steps from "./Components/Steps";

import AddContext from "./AddContext";

import { useNavigate } from "react-router-dom";

const Add = () => {
  const [step, setStep] = useState(-1);
  const [patient, setPatient] = useState({
    first_name: "",
    second_name: "",
    sir_name: "",
    email: "",
    phone: "",
    id_no: "",
    id: null,
  });

  const [kin, setKin] = useState({
    name: "",
    phone: "",
    relationship: "",
    gender: "",
    patient_id: "",
  });

  const [pic, setPic] = useState(null);

  const navigate = useNavigate();

  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  return (
    <AddContext.Provider
      value={{ step, setStep, patient, setPatient, pic, setPic, kin, setKin }}
    >
      <FormScreen backBtn={true} back={handleBack}>
        <FormLabel label="Add Patient" mt={2} mb={2}>
          <MdSick />
        </FormLabel>
        <Steps currentStep={step} close={() => navigate("../")} />
        <div className="w-full flex flex-row justify-center items-center">
          <Forms step={step} setStep={setStep} />
        </div>
      </FormScreen>
    </AddContext.Provider>
  );
};

export default Add;
