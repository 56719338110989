const colors = {
  primary: "#666CCC",
  primary_accent: "#6268BA",
  secondary: "#011638",
  yankees_blue: "#1D2939",
  auro_metal_saurus: "#667085",
  cadet_grey: "#98A1B5",
  bright_grey: "#EAECF0",
  ghost_white: "#F9FAFB",
  red: "#F51E1E",
  charcol: "#37474F",
  acid_green: "#BDCC13",
  blue: "#3D3AA9",
  white: "#FFFFFF",
  black: "#000000",
  pale_spring_bud: "#EDF7B5",
  transparent: "rgba(255,255,255,0)",
  pale_pink: "#FDCBE9",
  green: "#00ff00",
  pale_green: "#98fb98",
  olive: "#708238",
};

const colorNames = [
  "primary",
  "primary_accent",
  "secondary",
  "yankees_blue",
  "auro_metal_saurus",
  "cadet_grey",
  "bright_grey",
  "ghost_white",
  "red",
  "charcol",
  "acid_green",
  "blue",
  "white",
  "black",
  "pale_spring_bud",
  "transparent",
  "pale_pink",
];

export { colors, colorNames };
