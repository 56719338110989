import Steppers from "../../../../../../Components/Steppers/Steppers";

import { BsPersonPlus } from "react-icons/bs";

import { CgFileDocument } from "react-icons/cg";

const Stepper = ({ currentStep, close }) => {
  return (
    <Steppers currentStep={currentStep} close={close}>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <CgFileDocument color="white" />
        <p>Add Items Details</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <CgFileDocument color="white" />
        <p>Choose Item</p>
      </div>
    </Steppers>
  );
};

export default Stepper;
