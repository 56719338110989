const TableHeader = ({ children, bg, showDivider = true, h = 1 }) => {
  return (
    <div
      className={`w-full flex flex-row gap-x-5 py-1 items-center ${bg}`}
      style={{
        borderColor: "#DEE2E6",
        // borderTopWidth: h,
        borderBottomWidth: showDivider ? h : 0,
      }}
    >
      {children}
    </div>
  );
};

export default TableHeader;
