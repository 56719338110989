import React, { useContext } from "react";
import TRow from "./TRow";

import AllContext from "../../AllContext";

const TBody = () => {
  const { data } = useContext(AllContext);

  return (
    <>
      {data.map((doc) => (
        <TRow doc={doc} key={doc.id} data={data} />
      ))}
    </>
  );
};

export default TBody;
