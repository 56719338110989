import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../../Components/FinalTable";

import { convDate } from "./../../../../../../../Util/Time";

import TActions from "./TActions";

const TRow = ({ doc }) => {
  return (
    <TableRow>
      <TableCell w={90 / 6}>
        <TableText label={`${doc.first_name} ${doc.second_name}`} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.email || ""} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.phone || ""} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.id_no || ""} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={convDate(parseInt(doc.dob))} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.gender} />
      </TableCell>
      <div style={{ width: "10%" }} className=" ">
        <TActions doc={doc} />
      </div>
    </TableRow>
  );
};

export default TRow;
