const host = "prod.relax.mkombozihealthcare.co.tz";

const baseUrl = "https://api.mkombozihealthcare.co.tz/api";

const user = "admin";

const pass = "medico-prod";

const dbs = [
  "queue",
  "team",
  "team_roles",
  "patients",
  "relationships",
  "patient_allergies",
  "kins",
  "pics",
  "visits",
  "visit_types",
  "visit_steps",
  "spaces",
  "space_team",
  "space_types",
  "insurance",
  "insurance_scheme",
  "payments",
  "store_request",
  "hospital_queue",
];

let sp = { sort_field: "created_at", sort_order: "desc" };

const tables = [
  {
    name: "patients",
    url: "patient",
    params: sp,
  },
  {
    name: "hospital_queue",
    url: "hospital/records/visits",
    params: {
      ...sp,
      checked_in: true,
    },
  },
];

export { host, pass, user, baseUrl, dbs, tables };
