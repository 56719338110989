import React, { useContext } from "react";

import {
  TableRow,
  TableCell,
  SingleCheckBox,
  TableText,
} from "../../../../../../../Components/FinalTable";

import AddRequestContext from "../../context/AddRequestContext";

import NumberInput2 from "../../../../../../../Components/StateInput/NumberInput2";

const TRow = ({ doc }) => {
  const { id, setId, qty, setQty } = useContext(AddRequestContext);

  return (
    <TableRow>
      <TableCell w={90 / 6}>
        <TableText label={doc.name} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText
          label={
            doc.category ||
            (doc.inventory_item_categories
              ? doc.inventory_item_categories.name
              : doc.category_name)
          }
        />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText
          label={
            doc.unit_of_measure ||
            (doc.unit_of_measures
              ? doc.unit_of_measures.name
              : doc.unit_of_measure_name)
          }
        />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={doc.qty || doc.store_qty} />
      </TableCell>
      <TableCell w={90 / 5}>
        <NumberInput2 doc={doc} initial={1} config={{ max: doc.qty }} />
      </TableCell>
      <div style={{ width: "10%" }}>
        <TableCell>
          <SingleCheckBox checked_id={id} setCheckedId={setId} id={doc.id} />
        </TableCell>
      </div>
    </TableRow>
  );
};

export default TRow;
