import ReactTooltip from "react-tooltip";

const Tooltip = ({ children, id = "", txt1 = "", txt2 = "" }) => {
  return (
    <ReactTooltip
      backgroundColor="white"
      id={id}
      place="right"
      effect="solid"
      textColor="black"
      className=" shadow-lg rounded-2xl"
    >
      <div className="flex flex-col items-center font-pop-reg">
        <p className=" text-primary text-3xl">{children}</p>
        <span>{txt1}</span>
        <span>{txt2}</span>
      </div>
    </ReactTooltip>
  );
};

export default Tooltip;
