import Tooltip from "../../../Components/ToolTip";
import BtnRounded from "../../../../../Components/Buttons/BtnRounded";
import { MdPendingActions } from "react-icons/md";

const Pending = ({ open = false, active = false, setShowPending }) => {
  const id = "pending-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded bg="pale_green" click={() => setShowPending(true)}>
        <MdPendingActions />
      </BtnRounded>
      <Tooltip id={id} txt1="View Pending" txt2="Investigation And Procedures">
        <span className=" text-black">
          <MdPendingActions />
        </span>
      </Tooltip>
    </div>
  );
};

export default Pending;
