import { dblClick } from "@testing-library/user-event/dist/click";
import PouchDB from "pouchdb";
import pouchDbFind from "pouchdb-find";

PouchDB.plugin(pouchDbFind);

const usePouchCount = () => {
  return async ({ db_name, selector }) => {
    const db = new PouchDB(db_name);
    await db.createIndex({
      index: { fields: ["_id"] },
    });
    let docs = await db.find({
      selector: selector,
      fields: [],
      sort: ["_id"],
    });
    return docs.docs.length;
  };
};

export default usePouchCount;
