import React, { useState, useEffect } from "react";

import Badge from "../../../../../Components/Badges/Badge";

import { Table } from "../../../../../Components/FinalTable";

import TContext from "./TContext";

import THead from "./THead";
import TBody from "./TBody";

import { useParams } from "react-router-dom";

import ViewPatient from "./../Modals/ViewPatient";

import { useNavigate } from "react-router-dom";

import AddStep from "../Modals/AddStep";

import GoToNext from "./../Modals/Next";

import CheckoutModal from "../Modals/CheckoutPatient";

import useAxios from "../../../../../Hooks/useAxios";

import ViewFile from "../Modals/ViewFile";
import PatientDetail from "../Tooltips/PatientDetail";
import VisitDetail from "../Tooltips/VisitDetail";
import AddStepTip from "../Tooltips/AddStepTip";
import CheckoutTip from "../Tooltips/CheckoutTip";
import SendPatient from "../Tooltips/SendPatient";

import Pending from "../Tooltips/Pending";

import PendinInv from "../Modals/PendingInv";

import LoadingModal from "./../../../../../Components/Modals/LoadingModal";

const VisitStep = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pending, setPending] = useState(true);

  const [inv, setInv] = useState({
    investigations: [],
    prescriptions: [],
    procedures: [],
  });

  const [patientDetails, setPatientDetails] = useState({});
  const [visitDoc, setVisitDoc] = useState({});
  const [receptionist, setReceptionist] = useState({});

  const [showPatient, setShowPatient] = useState(false);
  const [showPending, setShowPending] = useState(false);
  const [pbtn, setPendingBtn] = useState(false);

  const [showAdd, setShowAdd] = useState(false);

  const [showFile, setShowFile] = useState(false);

  const [showNext, setShowNext] = useState(false);

  const [showCheckout, setShowCheckout] = useState(false);

  const [space, setSpaces] = useState([]);
  const [team, setTeam] = useState([]);

  const [loading, setLoading] = useState(false);

  const { visit } = useParams();
  const navigate = useNavigate();

  const request = useAxios();

  async function fetchSteps() {
    setLoading(true);
    let res = await request({
      method: "GET",
      url: `hospital/step/${visit}`,
    });
    //console.log(res);

    if (res === "error") {
      navigate("/dashboard/user-queue");
      return;
    }

    let pendingStuff = await request({
      method: "GET",
      url: `hospital/visit/pendings`,
      params: { visit_id: visit },
      isLoading: false,
    });

    if (
      pendingStuff.investigations.length === 0 &&
      pendingStuff.prescriptions.length === 0 &&
      pendingStuff.procedures.length === 0
    ) {
      setPendingBtn(false);
    } else {
      setPendingBtn(true);
    }

    setInv(pendingStuff);

    let spaces = await request({
      method: "GET",
      url: `team/space-team`,
      isLoading: false,
    });

    if (spaces === "error") {
      navigate("/dashboard/user-queue");
      return;
    }

    let allSpaces = [{ label: "Select Space", value: "" }];
    let teams = {};

    for (let space of spaces) {
      allSpaces.push({ label: space.space.name, value: space.space.id });
      teams[space.space.id] = [{ label: "Any", value: "" }];
      for (let doc of space.team) {
        teams[space.space.id].push({ label: doc.name, value: doc.id });
      }
    }

    setTeam(teams);
    setSpaces(allSpaces);

    setData(res.steps);

    setVisitDoc(res.visit);

    setPatientDetails(res.patient);
    setReceptionist(res.receptionist);
    setLoading(false);
  }
  useEffect(() => {
    async function initialize() {
      await fetchSteps();
    }
    initialize();
  }, []);

  return (
    <div className=" w-full h-full  mt-8">
      <TContext.Provider
        value={{
          data,
          setData,
          fetchSteps,
          receptionist,
          patientDetails,
        }}
      >
        <Table>
          <div className=" w-full flex justify-between mb-2 ">
            <div>
              <Badge
                label={
                  visitDoc.checked_out === true ? "Checked Out" : "In Hospital"
                }
                bg={visitDoc.checked_out === true ? "pale_pink" : "bright_grey"}
              />
            </div>
            <div className=" flex gap-x-2">
              {pbtn && (
                <Pending
                  setShowPending={setShowPending}
                  showPending={showPending}
                />
              )}

              <PatientDetail setShowPatient={setShowPatient} />

              <VisitDetail setShowFile={setShowFile} />

              {visitDoc.checked_out === false && (
                <AddStepTip setShowAdd={setShowAdd} />
              )}

              {visitDoc.checked_out === false && (
                <CheckoutTip setShowCheckout={setShowCheckout} />
              )}

              {visitDoc.checked_out === false && (
                <SendPatient setShowNext={setShowNext} />
              )}
            </div>
          </div>
          <THead />
          <TBody />
          {/* <TInfo /> */}
          <div style={{ height: "6em" }}></div>
        </Table>
        <ViewPatient
          patient={patientDetails}
          showModal={showPatient}
          setShowModal={setShowPatient}
          setPatientDetails={setPatientDetails}
        />

        <AddStep
          id={visit}
          showModal={showAdd}
          setData={setData}
          setShowModal={setShowAdd}
          fetchSteps={fetchSteps}
          space={space}
          team={team}
        />

        <CheckoutModal
          visitDoc={visitDoc}
          id={visit}
          showModal={showCheckout}
          setShowModal={setShowCheckout}
          fetchSteps={fetchSteps}
        />

        <ViewFile
          patient={patientDetails}
          receptionist={receptionist}
          visit={visitDoc}
          showModal={showFile}
          setShowModal={setShowFile}
        />

        {pending && (
          <PendinInv
            doc={inv}
            showModal={showPending}
            setShowModal={setShowPending}
            inv={inv}
          />
        )}

        <GoToNext
          id={visit}
          visitDoc={visitDoc}
          showModal={showNext}
          setShowModal={setShowNext}
          fetchSteps={fetchSteps}
        />
      </TContext.Provider>

      <LoadingModal showModal={loading} />
    </div>
  );
};

export default VisitStep;
