import heartBeat from "./../../Assets/Images/heartAsset.png";

import { BsArrowLeftCircle } from "react-icons/bs";

const FormScreen = ({ children, backBtn = false, back = () => {} }) => {
  return (
    <div
      className="w-full h-full overflow-y-auto flex flex-col relative "
      style={{ backgroundColor: "rgba(249, 250, 251, 1)" }}
    >
      <div className="w-full">{children[0]}</div>
      <div className="w-full">{children[1]}</div>
      <div className="flex w-full z-10 flex-1 justify-between items-center">
        {children[2]}
      </div>
      <div className=" w-full flex flex-row   gap-x-2   items-center ">
        <BackBtn show={backBtn} click={back} />
        <div className="flex flex-1"></div>
        <img alt="heart beat z-0" src={heartBeat} className=" " />
      </div>
    </div>
  );
};

const BackBtn = ({ show, click }) => {
  if (show) {
    return (
      <div
        className="flex  flex-row active:opacity-50  gap-x-3 items-center ml-11  cursor-pointer"
        style={{ zIndex: 300 }}
        onClick={click}
      >
        <p className=" text-primary text-3xl ">
          <BsArrowLeftCircle />
        </p>
        <p className=" text-black font-DM-Bold underline text-2xl">Back</p>
      </div>
    );
  }

  return null;
};

export default FormScreen;
