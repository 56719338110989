import Tooltip from "../../../Components/ToolTip";
import BtnRounded from "../../../../../Components/Buttons/BtnRounded";
import { FaPlusCircle } from "react-icons/fa";

const AddStepTip = ({ open = false, active = false, setShowAdd }) => {
  const id = "addstep-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded bg="cadet_grey" click={() => setShowAdd(true)}>
        <FaPlusCircle />
      </BtnRounded>
      <Tooltip id={id} txt1="Add a visit" txt2="step">
        <FaPlusCircle />
      </Tooltip>
    </div>
  );
};

export default AddStepTip;
