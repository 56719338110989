import React, { useState, useEffect } from "react";

import { colors } from "./../../../Util/colors";

const SelectInput = ({
  input,
  setInput,
  items = [],
  label = "",
  mt = 0,
  mb = 0,
}) => {
  function handleChange(txt) {
    setInput(txt);
  }

  return (
    <div
      className="flex flex-col"
      style={{ marginTop: `${mt}em`, marginBottom: `${mb}em` }}
    >
      <label className="font-DM-Med text-base">{label}</label>
      <select
        value={input}
        onChange={(e) => handleChange(e.target.value)}
        className={
          "border-2 rounded-lg w-full py-2 px-8 placeholder-cadet_grey"
        }
        style={{
          borderColor: colors["bright_grey"],
        }}
      >
        {items.map((item, index) => (
          <option
            key={index}
            onChange={(e) => handleChange(e.target.value)}
            value={item.value}
          >
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectInput;
