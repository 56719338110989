import BasicModal from "./../../../../../Components/Modals/BasicModal";

import { convDate3 } from "./../../../../../Util/Time";

import usePouch from "../../../../../Hooks/usePouch";
import { useEffect, useState } from "react";

const ViewFile = ({
  patient,
  receptionist,
  visit,
  showModal,
  setShowModal,
}) => {
  const { fetchById } = usePouch();
  const [vtype, setVType] = useState("..");

  // useEffect(()=>{
  //   async function getVisitType(){
  //     let typ = await fetchById({db: 'visit_types', id: visit.visit_type_id.toString()})
  //     setVType(typ)
  //   }
  //   getVisitType()
  // },[visit])

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <p className="flex w-full justify-center text-2xl font-DM-Bold text-gray-700">
        Visit Details
      </p>
      <div
        style={{ width: "60em" }}
        className=" flex items-center justify-center pb-7"
      >
        <div className="w-full ml-5 mt-8 mr-5 bg-white rounded-lg shadow-md ">
          <div className="mb-5 pr-6">
            <div className="flex flex-row gap-x-8 items-center mt-5">
              {patient.pic && (
                <img
                  className=" ml-4 p-6"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                  src={`data:image/jpeg;base64, ${patient.pic}`}
                  alt="bed"
                />
              )}
              <div className="px-5">
                <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                  Checked in By
                </h5>
                <p className="mb-3 font-normal text-gray-700 ">
                  {receptionist.name}
                </p>
                <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                  Check In Date
                </h5>
                <p className="mb-3 font-normal text-gray-700 ">
                  {convDate3(visit.created_at)}
                </p>
              </div>
            </div>

            <div className="flex flex-col p-4 space-y-8">
              <div className="grid grid-cols-3">
                <div>
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Visit Type
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {vtype.name}
                  </p>
                </div>
                <div className="">
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Payment Method
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {visit.default_payment_method}
                  </p>
                </div>
                <div className="">
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Member Number
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {visit.member_no}
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-3">
                <div className="">
                  <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-700 ">
                    Referral Number
                  </h5>
                  <p className="mb-3 font-normal text-gray-700 ">
                    {visit.referral_no}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

export default ViewFile;
