import React, { useEffect, useState, useContext } from 'react'
import { TableTabs } from '../../../../../../../Components/FinalTable';
import usePouchCount from '../../../../../../../Hooks/usePouchCount';
import RequestsAll from '../../context/RequestsContext'

const Tabs = () => {
  
  const {
    setFixed,
    resetQuery,
    isFiltered,
    setIsFiltered,
    showLive,
    tabsData,
    handleFilterQuery,
    handleClearFilters,
  } = useContext(RequestsAll);

  const [tabs, setTabs] = useState([
    {title: `All (${tabsData.all || 0 })`, color: "primary", no: null},
    {title: "Approved", color: "primary", no: null},
    {title: "Pending", color: "red", no: null},
    {title: "Cancelled", color: "red", no: null},
    
  ]);

  const [activeTab, setActiveTab] = useState("All");

  const countDocs = usePouchCount();

  useEffect(()=>{
    setTabs([
      {title: `All (${tabsData.all || 0 })`, color: "primary", no: null},
      {title: `Approved (${tabsData.approved || 0})`, color: "primary", no: null},
      {title: `Pending (${tabsData.pending || 0})`, color: "red", no: null},
      {title: `Cancelled (${tabsData.canceled || 0})`, color: "red", no: null},
    ]);
  },[tabsData])

  useEffect(() => {
    if (isFiltered) return;
    setActiveTab("All");
  }, [isFiltered]);

  useEffect(() => {
    const countAsync = async () => {
      let db_name = "store_request";
      let All = await countDocs({
        db_name,
        selector: {},
      });

      let approved_Docs = await countDocs({
        db_name,
        selector: {
          status: { $eq: "approved"},
        },
      });
      let pending_Docs = await countDocs({
        db_name,
        selector: {
          status: { $eq: "pending" },
        },
      });

      let cancelled_Docs = await countDocs({
        db_name,
        selector: {
          status: { $eq: "canceled"}
        },
      });

      
      const newTabs = [...tabs];

      newTabs[0].no = All;
      newTabs[1].no = approved_Docs;
      newTabs[2].no = pending_Docs;
      newTabs[3].no = cancelled_Docs;
      

      setTabs(newTabs);

    };
    countAsync();
  }, []);

  async function handleTab(tab) {
    setActiveTab(tab.title);
    if (tab.title.includes("All")) {
      if(showLive){
        handleFilterQuery({})
        return;
      }
      setFixed({});
      handleClearFilters();
      return;
    }

    if (tab.title.includes("Approved")) {
      if(showLive){
        handleFilterQuery({status: "approved"})
        return;
      }
      setFixed({
        status: { $eq: "approved"}
      });
      setIsFiltered(true);
      await resetQuery();
      return;
    }

    if (tab.title.includes("Pending")) {
      if(showLive){
        handleFilterQuery({status: "pending"})
        return;
      }
      setFixed({
        status: { $eq: "pending"}
      });
      setIsFiltered(true);
      await resetQuery();
      return;
    }

    if (tab.title.includes("Cancelled")) {
      if(showLive){
        handleFilterQuery({status: "canceled"})
        return;
      }
      setFixed({
        status:{ $eq: "canceled"}
      });
      setIsFiltered(true);
      await resetQuery();
      return;
    };
   

  };

  return <TableTabs tabs={tabs} activeTab={activeTab} handleTab={ handleTab } /> ;
  
};

export default Tabs;