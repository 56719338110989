import React, { useContext, useState, useRef } from "react";
import TextInput from "../../../../../../Components/Inputs/TextInput";
import SelectInput from "../../../../../../Components/Inputs/SelectInput";

import DatePicker from "../../../../../../Components/Inputs/DatePicker";
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";
import AddContext from "../AddContext";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import { convDate2 } from "../../../../../../Util/Time";

const MoreDetails = () => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);

  const { patient, setPatient, setStep } = useContext(AddContext);

  const formErrors = useHandleFormError();

  async function submit() {
    await setShowError(showError + 1);

    if (formErrors({ input })) return;

    await setPatient({ ...patient, ...input.current.form });
    setStep((c) => c + 1);
  }

  return (
    <div
      className=" m-auto p-10 shadow-md rounded-lg mt-5 bg-white"
      style={{ width: "60%" }}
    >
      <div className="w-full flex gap-x-6">
        <div className=" flex-1">
          <DatePicker
            initial={convDate2(parseInt(patient.dob))}
            input={input}
            showError={showError}
            inputKey="dob"
            mt={-0.5}
            config={{
              required: true,
              disabled: false,
              label: "Select Date Of Birth",
              max: convDate2(Date.now()),
            }}
          />
          <TextInput
            initial={patient.nationality || ""}
            input={input}
            showError={showError}
            inputKey="nationality"
            mt={0.5}
            config={{
              required: true,
              label: "Nationality",
              placeholder: "eg. Tanzanian",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
            initial={patient.occupation || ""}
            input={input}
            showError={showError}
            inputKey="occupation"
            mt={0.5}
            config={{
              required: false,
              label: "Occupation",
              placeholder: "eg. Business",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <SelectInput
            initial={patient.gender || ""}
            input={input}
            showError={showError}
            inputKey="gender"
            mt={0.5}
            config={{
              disabled: false,
              required: true,
              firstDisabled: false,
              items: [
                { label: "Select Gender", value: "" },
                { label: "Male", value: "Female" },
                { label: "Female", value: "Female" },
                { label: "Other", value: "Other" },
              ],
              label: "Select Gender",
            }}
          />
        </div>
        <div className=" flex-1">
          <TextInput
            initial={patient.religion || ""}
            input={input}
            showError={showError}
            inputKey="religion"
            mt={0.5}
            config={{
              required: true,
              label: "Religion",
              placeholder: "eg. Christian",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <TextInput
            initial={patient.district || ""}
            input={input}
            showError={showError}
            inputKey="district"
            mt={0.5}
            config={{
              required: true,
              label: "Wilaya",
              placeholder: "",
              maxChar: 250,
              minChar: 4,
            }}
          />
          <TextInput
            initial={patient.ward || ""}
            input={input}
            showError={showError}
            inputKey="ward"
            mt={0.5}
            config={{
              required: true,
              label: "Kata",
              placeholder: "Kata",
              maxChar: 250,
              minChar: 4,
            }}
          />
        </div>
      </div>

      <BtnRounded mt={2} click={submit}>
        Continue
      </BtnRounded>
    </div>
  );
};

export default MoreDetails;
