import React, { useRef, useContext, useState } from "react";

import FilterModal from "../../../../../../Components/Modals/FilterModal";

import CheckBox from "../../../../../../Components/Inputs/CheckBox";

import DatePicker from "../../../../../../Components/Inputs/DatePicker";

import { convDate2, toTimeStamp } from "../../../../../../Util/Time";
import useApp from "../../../../../../Hooks/useApp";

import AllContext from "../../AllContext";
import NumberInput from "../../../../../../Components/Inputs/NumberInput";

const TblFilter = ({ showFilter, setShowFilter }) => {

  const { setIsFiltered, handleFilterQuery } = useContext(AllContext);

  const input_initial = {
    form: {
      min_qty: "",
      max_qty: "",
      pending: "",
      canceled: "",
      approved: "",
      fixed_asset: "",
      is_inventory: "",
    },
    err: {},
  };

  const input = useRef(input_initial);

  const { setIsLoading, setModalMessage, setShowFormModal } = useApp();

  
  async function applyFilters() {
    try {
      setIsLoading(true);
      let the_input = input.current.form;

      let max_qty = {};
      let min_qty = {};
      let pending = {}
      let canceled = {}
      let approved = {}
      let fixed_asset = {}
      let is_inventory = {}

     
      if (the_input.min_qty !== "") {
        min_qty = {
          qty: {
            $lte: parseInt(the_input.min_qty),
          },
        };
      }

      if (the_input.max_qty !== "") {
        max_qty = {
          qty: {
            $gte: parseInt(the_input.max_qty),
          },
        };
      }

      if (the_input.pending !== "") {
        pending = {
          status: {
            $eq: "pending",
          },
        };
      }
      if (the_input.approved !== "") {
        approved = {
          status: {
            $eq: "approved",
          },
        };
      }
      if (the_input.canceled !== "") {
        canceled = {
          status: {
            $eq: "canceled",
          },
        };
      }
      if (the_input.fixed_asset !== "") {
        fixed_asset = {
          is_attending: {
            $eq: !the_input.fixed_asset || false,
          },
        };
      }
      if (the_input.is_inventory !== "") {
        is_inventory = {
          is_attending: {
            $eq: the_input.is_inventory || false,
          },
        };
      }

     

      const index = ["created_at"];
      const sort = [{ created_at: "desc" }];
      const selector = {
        created_at: { $gte: null },
      };

      let secondarySelector = {
        ...max_qty,
        ...min_qty,
        ...pending,
        ...canceled,
        ...approved,
        ...fixed_asset,
        ...is_inventory,
      };
      
      handleFilterQuery(index, sort, selector, secondarySelector);
      setIsFiltered(true);
      input.current = input_initial;
    } catch (e) {
      setModalMessage("Filter Fail. Contact Admin");
      setShowFormModal(true);

    } finally {
      setIsLoading(false);
    }
  }

  return (
    <FilterModal showFilter={showFilter} setShowFilter={setShowFilter} apply={applyFilters}>
      <div style={{ width: "320px" }}>
        <div className="flex flex-col py-6 gap-y-4">
        </div>

        <div className="flex flex-col  gap-y-4">
          <div>
            <h3 className="uppercase mt-2 mb-2  font-DM-Reg font-normal text-sm text-auro_metal_saurus">
              CURRENTLY
            </h3>
            <div className=" flex gap-x-4">
            <div className=" flex flex-col gap-y-2 ">
            <CheckBox
              label={"Attending To"}
              input={input}
              inputKey="is_inventory"
              initial=""
            />
             <CheckBox
              label={"Not Attending "}
              input={input}
              inputKey="fixed_asset"
              initial=""
            />
            </div>
            </div>
          </div>
        </div>
      </div>
    </FilterModal>
  );
};

export default TblFilter;
